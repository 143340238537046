import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
const initialState = {
  language: "",
  menuList:"",
  pageData:"",
  error:"",
  loading:"",
  
};


let url = "https://kala-prerana-uat.sensez9.tech/prerna-school-website/web/psw/api/v1/header_nav_list?language=";
let urlPage ="https://kala-prerana-uat.sensez9.tech/prerna-school-website/web/gex/api/v1/get-page?path=";
export const menuListAPI = createAsyncThunk("menu/list", async (params) => {
  let language= (params == undefined || params == null || params == "" || params == "English") ? "en": (params == "Hindi" || params == "हिंदी") ? "hi":params
    const response = await axios.get(url+language);
    return response.data;
  });
  export const pageDetailsAPI = createAsyncThunk("page/details", async (params) => {
    let {path,language} = params;
    // let languageTest= (language == undefined || language == null || language == "" || language == "English" || language == "en") ? "en": (language == "Hindi" || language == "हिंदी" || language == "hi") ? "hi":language
    const response = await axios.get(urlPage+path+`&language=${language}`);
    return response.data;
  });

  // export const changedLanguage = createAsyncThunk("page/language", async (params) => {
  //   let languageTest= (params == undefined || params == null || params == "" || params == "English") ? "en": (params == "Hindi" || params == "हिंदी") ? "hi":params
  
  //   return languageTest;
  // });

  const rootSlice = createSlice({
    name: "root",
    initialState,
    extraReducers: (builder) => {
        builder.addCase(menuListAPI.pending, (state) => {
            state.version = "";
          });
          builder.addCase(menuListAPI.fulfilled, (state, action) => {
            state.error = false;
            state.menuList = action?.payload.data.list;
          });
          builder.addCase(menuListAPI.rejected, (state) => {
            state.version = "";
          });

          builder.addCase(pageDetailsAPI.pending, (state) => {
            state.pageData = "";
          });
          builder.addCase(pageDetailsAPI.fulfilled, (state, action) => {
            // console.log("pageDetailsAPI.fulfilled",action?.payload?.data)
            state.error = false;
            state.pageData = action?.payload?.data;
          });
          builder.addCase(pageDetailsAPI.rejected, (state) => {
            state.pageData = "";
          });


          // builder.addCase(changedLanguage.pending, (state) => {
          //   state.language = "";
          // });
          // builder.addCase(changedLanguage.fulfilled, (state, action) => {
          //   state.error = false;
          //   state.language = action;
          // });
          // builder.addCase(changedLanguage.rejected, (state) => {
          //   state.language = "";
          // });
    }
  })


  export default rootSlice.reducer;
