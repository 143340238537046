import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import hamburgerIcon from "../../assets/content/hamburger.svg";
import prernaHindiIcon from "../../assets/content/prernahindi.png";
import azadiKaMahotsavIcon from "../../assets/content/azadi-ka-mahotsav.svg";
import ministryOfCulture from "../../assets/content/ministryofculture.svg";
import textToSpeechIcon from "../../assets/content/textToSpeech.svg";
import disabledIcon from "../../assets/content/disabled.svg";
import sitemapIcon from "../../assets/content/sitemap.svg";
import HomeIcon from "../../assets/content/homePAge.svg";
import NewLogoIcon from "../../assets/content/newlogo.png";
import { IconButton, Typography } from "@mui/material";
import "../../views/Content/index.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { menuListAPI, pageDetailsAPI } from "../../redux/slice";
import {
  Directions,
  KeyboardArrowDown,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Collapse, List, ListItem, ListItemText } from "@mui/material";
import { setEnglish, setHindi } from "../../redux/booleanSlice";
const useStyles = createUseStyles(() => ({
  container: {
    height: "50px",
    padding: "10px 40px",
    display: "flex",
    alignItems: "center",
    "@media (min-width: 320px)": {
      padding: "10px 10px",
    },
    "@media (min-width: 374px)": {},
    "@media (min-width: 1200px)": {
      padding: "10px 80px",
    },
  },
  firstInner: {
    display: "flex",
    alignItems: "center",
    width: "auto",
    "@media (min-width: 320px)": {
    },
    "@media (min-width: 374px)": {},
    "@media (min-width: 1200px)": {
    },
  },
  thirdInner: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    width: "150px",
    "@media (min-width: 320px)": {
      justifyContent: "flex-end",
    },
    "@media (min-width: 374px)": {},
    "@media (min-width: 1200px)": {},
  },
  secondInner: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    "@media (min-width: 320px)": {
    },
    "@media (min-width: 374px)": {},
    "@media (min-width: 1200px)": {
      position: "relative",
      right: "3.5rem",
    },
  },
  firstImage: {
    width: "85px",
  },
  firstImagePointer: {
    cursor: "pointer",
    "@media (min-width: 320px)": {
      width: "30px",
    },
    "@media (min-width: 374px)": {
      width: "40px",
    },
    "@media (min-width: 390px)": {
      width: "50px",
    },
    "@media (min-width: 410px)": {
      width: "50px",
    },
    "@media (min-width: 1200px)": {
      width: "75px",
    },
  },
  firstImagePointer2: {
    cursor: "pointer",
    "@media (min-width: 320px)": {
      width: "30px",
    },
    "@media (min-width: 374px)": {
      width: "40px",
    },
    "@media (min-width: 390px)": {
      width: "50px",
    },
    "@media (min-width: 410px)": {
      width: "50px",
    },
    "@media (min-width: 1200px)": {
      width: "75px",
      position:"relative",
      top:"3px",
    },
  },
  middleImage: {
    height: "36px",
  },
  hamburger: {
    height: "15px",
    cursor: "pointer",
  },
  divider: {
    display: "block",
    height: "30px",
    width: "1px",
    borderLeftColor: "#58595B",
    borderLeftWidth: "1.5px",
    borderLeftStyle: "solid",
    "@media (min-width: 320px)": {
      margin: "0px 1px",
    },
    "@media (min-width: 374px)": {

    },
    "@media (min-width: 390px)": {
  
    },
    "@media (min-width: 410px)": {
  
    },
    "@media (min-width: 760px)": {
      margin: "0px 15px",
    },
    "@media (min-width: 1200px)": {
      // width: "75px",
      // position:"relative",
      // top:"3px",
    },
  },
  menuItemsLeft: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    marginLeft: "1rem",
  },
  menuItemsLeftActive: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    marginLeft: "1rem",
    backgroundColor: "lightgrey",
  },
  menuItems: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
  },
  menuItemsActive: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "lightgrey",
  },
  menuItemsRivers: {
    // textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
  },
  menuItemsActiveRivers: {
    // textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "lightgrey",
  },
  menuItemsActiveHindi: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "lightgrey",
    fontSize: "18px",
  },
  menuItemsHindi: {
    textTransform: "capitalize",
    fontFamily: "Poppins, sans-serif",
    backgroundColor: "lightgrey",
    fontSize: "18px",
  },
  homeIcon: {
    width: "30px",
    position: "relative",
    right: "25px",
    cursor: "pointer",
  },
  langEng: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "@media (min-width: 320px)": {
      // width: "45%",
      height: "auto",
      fontSize: "12px",
      marginRight: "30px",
    },
    "@media (min-width: 360px)": {

    },
    "@media (min-width: 374px)": {
      // width: "52%",
    },
    "@media (min-width: 390px)": {},
    "@media (min-width: 410px)": {
      // width: "54%",

    },
    "@media (min-width: 430px)": {
      // width: "55%",

    },
    "@media (min-width: 540px)": {
      // width: "65%",

    },
    "@media (min-width: 768px)": {
      // width: "77%",
    },
    "@media (min-width: 820px)": {
      // width: "77%",
    },

    "@media (min-width: 1200px)": {
      fontSize: "16px",
      marginRight: "40px",
    },
  },
  langHindi: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginRight: "40px",
    fontSize: "18px",
  },
}));

const Header = (props) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedSubItem, setSelectedSubItem] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [listOpen, setListOpen] = useState(null);
  const [expandedList, setExpandedList] = useState([]);
  const [expandedDropDown, setExpandedDropDown] = useState([]);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const dispatch = useDispatch();
  const storeData = useSelector((state) => state.root?.menuList);
  const storeDataPage = useSelector((state) => state.root?.pageData);
  const languageStore = useSelector((state) => state.boolean?.language);
  const [selectedLang, setSelectedLang] = useState("English");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const path = window.location.pathname;
  const segments = path.split("/").filter((segment) => segment !== "");
  const handleClose2 = (lang) => {
    // dispatch(changedLanguage(lang))
    let languageTest =
      lang == undefined ||
      lang == null ||
      lang == "" ||
      lang == "English" ||
      lang == "en"
        ? "en"
        : lang == "Hindi" || lang == "हिंदी" || lang == "hi"
        ? "hi"
        : lang;
    if (
      lang !== undefined &&
      lang !== null &&
      lang !== "" &&
      typeof lang == "string"
    ) {
      setSelectedLang(lang);

      if (languageTest == "en") {
        dispatch(setEnglish());
      } else {
        dispatch(setHindi());
      }
    }
    setAnchorEl2(null);

    if (
      segments[segments.length - 1] == "home" ||
      (segments[segments.length - 1] == "hi" &&
        segments[segments.length - 2] == "home")
    ) {
      navigate("/home");
      // fetchData("dhammacakkappavattana-sutta", lang);
    } 
    else if (
      segments[segments.length - 2] == "menu" ||
      (segments[segments.length - 1] == "hi" &&
        segments[segments.length - 2] == "menu") ||
      segments[segments.length - 2] == "menu"
    ) {
      if (segments[segments.length - 1] == "hi") {
        expandedDropDown.forEach((element, index) => {
          // console.log("element",element)
          if (element?.pagelink.slice(1) == segments[segments.length - 2]) {
            navigate(`/menu/${segments[segments.length - 2]}`);
          }
        });
        //      navigate (`/menu/${segments[segments.length - 2]}/${languageTest}` , {
        //   state: {
        //     mainPath: element?.pagelink,
        //     pageHeader: element?.title,
        //     pageContent: element?.inner_page,
        //     language: languageTest,
        //     currentIndex: index,
        //     totalMenuData:expandedDropDown
        //   },
        // });
        // navigate('/menuName')
      } else {
        // navigate(`/menu/${segments[segments.length - 2]}/${languageTest}`)
        // navigate (`/menu/${segments[segments.length - 2]}/${languageTest}` , {
        //   state: {
        //     mainPath: element?.pagelink,
        //     pageHeader: element?.title,
        //     pageContent: element?.inner_page,
        //     language: languageTest,
        //     currentIndex: index,
        //     totalMenuData:expandedDropDown
        //   },
        // });
      }
    } else {
      handleReloadPage(
        segments[segments.length - 3],
        segments[segments.length - 2],
        languageTest
      );
    }
  };
  const classes = useStyles();
  const navigate = useNavigate();
  let URL =
    " https://kala-prerana.ignca.gov.in/prerna-school-website//web/gex/api/v1/get-page?path=";
  useEffect(() => {
    if (listOpen !== null && listOpen !== "" && listOpen !== undefined) {
      let newList = expandedDropDown[listOpen]?.inner_page;
      setExpandedList(newList);
      setSelectedItem(expandedDropDown[listOpen]?.pagelink);
    }
  }, [listOpen]);
  useEffect(() => {
    // fetchMenuData(selectedLang);

    // if (selectedLang == "English" || selectedLang == "en") {
    //   setExpandedDropDown(apiList);
    //   // setExpandedDropDown(list);
    // } else {
    //   setExpandedDropDown(listHindi);
    // }
    if (selectedLang !== "") loadMenu();
  }, [selectedLang]);
  useEffect(() => {
    if (storeData !== undefined && storeData !== null && storeData !== "") {
      setExpandedDropDown(storeData);
    }
  }, [storeData]);

  const [open3, setOpen3] = useState(false);

  const toggleSubMenu = (index, type) => {
    if (type == "open") {
      setOpen3(true);
      setListOpen(index);
    } else {
      setOpen3(false);
      setListOpen(null);
    }
  };

  useEffect(() => {
    loadMenu();
  }, []);

  const handleReloadPage = async (category, subCategory, lang) => {
    setSelectedSubCategory(subCategory);
    setSelectedCategory(category);
    dispatch(pageDetailsAPI({ path: category, language: lang }));
    let languageTest =
      lang == undefined ||
      lang == null ||
      lang == "" ||
      lang == "English" ||
      lang == "en"
        ? "en"
        : lang == "Hindi" || lang == "हिंदी" || lang == "hi"
        ? "hi"
        : lang;
    navigate(`/content/${category}/${subCategory}/${languageTest}`);
  };
  useEffect(() => {
    if (
      storeDataPage !== null &&
      storeDataPage !== undefined &&
      storeDataPage !== "" &&
      storeDataPage?.length > 0
    ) {
      if (storeDataPage?.ContentSection?.length > 0) {
        let arr = [];
        let obj = storeDataPage?.ContentSection;

        obj.map((element) => {
          if (element?.key == selectedSubCategory) arr.push(element);
        });

        navigate(
          `/content/${selectedCategory}/${selectedSubCategory}/${languageStore}`
        );
      } else {
        navigate(
          `/content/${selectedCategory}/${selectedSubCategory}/${languageStore}`
        );
      }
    }
  }, [storeDataPage]);

  const loadMenu = () => {
    dispatch(menuListAPI(selectedLang));
  };
  useEffect(() => {
    setSelectedSubItem("");
  }, [selectedItem])
  
  return (
    <div className={classes.container}>
      <div className={classes.firstInner}>
        <img
          src={ministryOfCulture}
          alt="ministryofculture"
          className={classes.firstImagePointer}
          onClick={() => {
            navigate(
              selectedLang == "en" ||
                selectedLang == "English" ||
                selectedLang == ""
                ? `/home`
                : "/home/hi"
            );
            setSelectedItem("home");
          }}
        />
       
                <div className={classes.divider}></div>
                <img
          src={NewLogoIcon}
          alt="ministryofculture"
          className={classes.firstImagePointer2}
          onClick={() => {
            navigate(
              selectedLang == "en" ||
                selectedLang == "English" ||
                selectedLang == ""
                ? `/home`
                : "/home/hi"
            );
            setSelectedItem("home");
          }}
        />
        <div className={classes.divider}></div>
        <img
          src={prernaHindiIcon}
          alt="prernaLogo"
          className={classes.firstImagePointer}
          onClick={() => {
            navigate(
              selectedLang == "en" ||
                selectedLang == "English" ||
                selectedLang == ""
                ? `/home`
                : "/home/hi"
            );
            setSelectedItem("home");
          }}
        />
      </div>
      <div className={classes.secondInner}>
        <img
          src={azadiKaMahotsavIcon}
          alt="azadi ka mahotsav"
          className={classes.middleImage}
        />
      </div>
      <div className={classes.thirdInner}>
        <img
          src={hamburgerIcon}
          alt="hamburger"
          className={classes.hamburger}
          onClick={handleClick}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
            setOpen3(null);
            setListOpen(null);
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <List component="nav">
            {expandedDropDown?.length > 0 &&
              expandedDropDown.map((element, index) => {
                return (
                  <>
                    <ListItem
                      button
                      className={
                        element?.title == selectedItem
                          ? classes.menuItemsActive
                          : classes.menuItems
                      }
                    >
                      <div className="subList">
                        <ListItemText
                          // primary={element.replace("-", " ")}
                          primary={element?.title}
                          onClick={() => {
                            setSelectedItem(element?.title);
                            if (element?.title == "home") {
                              navigate("/home");
                            } else if (element?.inner_page?.length == 1) {
                              handleReloadPage(
                                element?.pagelink.slice(1),
                                element?.inner_page[0].inner_page_url,
                                selectedLang
                              );
                              setAnchorEl(null)
                            } else {                        
                              let languageTest =
                                selectedLang == undefined ||
                                selectedLang == null ||
                                selectedLang == "" ||
                                selectedLang == "English" ||
                                selectedLang == "en"
                                  ? "en"
                                  : selectedLang == "Hindi" ||
                                    selectedLang == "हिंदी" ||
                                    selectedLang == "hi"
                                  ? "hi"
                                  : selectedLang;
                              navigate(`/menu/${element?.pagelink.slice(1)}`);

                              if (open3 && listOpen == index) {
                                toggleSubMenu(index, "close");
                              } else {
                                toggleSubMenu(index, "open");
                                
                              }
                            }
                          }}
                        />
                        {element?.inner_page?.length > 1 ? (
                          open3 && listOpen == index ? (
                            <ExpandLess
                              onClick={() => toggleSubMenu(index, "close")}
                            />
                          ) : (
                            <ExpandMore
                              onClick={() => toggleSubMenu(index, "open")}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </ListItem>

                    <Collapse
                      in={open3 && listOpen == index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {expandedList.map((subElement, subIndex) => {
                          return (
                            <ListItem
                              button
                              className={
                                subElement?.inner_page_sub_title == selectedSubItem
                                  ? classes.menuItemsLeftActive
                                  : classes.menuItemsLeft
                              }
                            >
                              <ListItemText
                                onClick={() => {
                                  // console.log("subelement")
                                  setSelectedSubItem(subElement?.inner_page_sub_title)
                                  handleReloadPage(
                                    selectedItem.slice(1),
                                    subElement?.inner_page_url,
                                    selectedLang
                                  );
                                  setAnchorEl(null);

                                }}
                                primary={
                                  selectedLang == "en" ||
                                  selectedLang == "English"
                                    ? subElement?.inner_page_sub_title?.replace(
                                        /-/g,
                                        " "
                                      )
                                    : subElement?.inner_page_sub_title?.replace(
                                        /-/g,
                                        " "
                                      )
                                }
                              />
                            </ListItem>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                );
              })}
          </List>
        </Menu>
        <div>
          <img
            src={HomeIcon}
            alt="Home Icon"
            className={classes.homeIcon}
            onClick={() => {
              navigate(
                selectedLang == "en" || selectedLang == "English"
                  ? `/home`
                  : "/home/hi"
              );
              setSelectedItem("home");
            }}
          />
        </div>
        <Typography
          className={
            segments[segments.length - 1] == "hi"
              ? classes.langHindi
              : classes.langEng
          }
          onClick={handleClick2}
        >
          {segments[segments.length - 1] == "hi" ? "हिंदी" : selectedLang}
          <IconButton sx={{ padding: "0px" }}>
            <KeyboardArrowDown />
          </IconButton>
        </Typography>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl2}
          open={open2}
          onClose={() => setAnchorEl2(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            className={
              selectedLang == "en" ||
              segments[segments.length - 1] == "en" ||
              selectedLang == "English"
                ? classes.menuItemsActive
                : classes.menuItems
            }
            onClick={() => {
              handleClose2("English");
            }}
          >
            English
          </MenuItem>
          <MenuItem
            className={
              selectedLang == "hi" ||
              selectedLang == "हिंदी" ||
              segments[segments.length - 1] == "hi"
                ? classes.menuItemsActiveHindi
                : classes.menuItemsHindi
            }
            onClick={() => {
              handleClose2("हिंदी");
            }}
          >
            हिंदी
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Header;
